<template>
  <div class="detail-page">
    <div class="detail-page-inner">
      <div class="detail-content">
        <template v-if="!loading">
          <div id="info" v-if="detail && detail.filename">
            <div class="detail-wrap" v-if="detail.filelist == null">
              <h1 class="filename">{{ detail.filename }}</h1>
            </div>
            <div
              class="detail-wrap"
              v-if="detail.filelist && detail.filelist.length > 1"
            >
              <h2 class="detail-header">
                <span>文件夹内部目录</span>
              </h2>
              <h1 class="filename">{{ detail.filename }}</h1>
              <div class="detail-inner-wrap">
                <template v-for="(detailItem, detailIndex) in detail.filelist">
                  <div
                    class="detail-item"
                    v-if="detailIndex > 0"
                    :key="`detail${detailIndex}`"
                  >
                    <p class="detail-item-wrap">
                      <span
                        class="detail-item-title"
                        v-html="detailItem.filename"
                      ></span>
                      <span v-if="detailItem.size > 0">
                        - {{ detailItem.size | bytesToSize }}</span
                      >
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <div class="resource-meta">
              <span class="meta-item" v-if="detail.size > 0"
                ><span class="label">文件大小：</span>
                {{ detail.size | bytesToSize }}</span
              >
              <span class="meta-item"
                ><span class="label">更新时间：</span>
                {{ detail.ctime && detail.ctime.substr(0, 10) }}</span
              >
              <a
                class="meta-item report"
                :href="`http://report.3453k.cn?fid=${id}&type=${this.type}`"
                target="_blank"
                ><span class="label">举报资源</span></a
              >
            </div>
            <div class="resource-meta">
              <span
                class="meta-item copy-item"
                v-if="pwd"
                @click.stop="copy(pwd)"
              >
                <span class="label">提取密码</span>
                {{ pwd }}
                <span class="copy">点击复制</span>
              </span>
              <span v-if="isCopied" class="tip">提取码复制成功</span>
            </div>

            <div class="check-wrap">
              <p class="checking-tip" v-if="isCheckingUrlValid">
                正在检查该链接是否有效...
              </p>
              <p class="result-tip" v-if="!isCheckingUrlValid">
                <span class="valid" v-if="valid == 1"
                  >该链接有效，可以访问</span
                >
                <span class="invalid" v-if="valid == -1"
                  >该链接已经失效，无法访问</span
                >
              </p>
            </div>

            <div class="button-wrap" v-if="url">
              <a class="button" :href="url" target="_blank">前往网盘下载</a>
            </div>
            <div class="button-wrap" v-if="!url">
              <a class="button">正在获取链接</a>
            </div>
          </div>
          <div id="statement" v-if="detail && detail.filename">
            <h2>声明&链接</h2>
            <p>
              『{{ detail.filename }}』资源内容来源于
              <a
                :href="
                  `https://tieba.baidu.com/f?ie=utf-8&fr=search&kw=${encodeURIComponent(
                    detail.filename
                  )}`
                "
                target="_blank"
                rel="nofollow external noopener"
              >
                贴吧-> 贡献用户：{{
                  (userinfo && userinfo.unionid.substr(0, 8)) || "匿名用户"
                }}*</a
              >。
            </p>
            <p class="em">
              请认真阅读以下说明，您只有在了解并同意该说明后，才可继续访问本站。
            </p>
            <p>
              1. 请认准{{ PROJECT_NAME }}唯一网址({{ DOMAIN }})，除网址后缀为
              .com 之外，其他一切包含"{{
                PROJECT_NAME
              }}"等类似字样的网站等平台均为假冒，请注意甄别。
            </p>
            <p>
              2.
              全站链接通过搜索引擎爬虫程序收集自百度网盘公开分享链接，以非人工方式自动生成，{{
                PROJECT_NAME
              }}本身不储存、复制、传播、控制编辑任何网盘资源文件，也不提供下载服务，其链接跳转至百度网盘，文件的有效性和安全性需要您自行判断。
            </p>
            <p>
              3.
              {{
                PROJECT_NAME
              }}遵守相关法律法规，坚决杜绝一切违规不良信息，如您发现任何涉嫌违规的网盘信息，请立即向百度网盘官方网站举报，并及时反馈给我们进行屏蔽删除。同时您必须了解并同意本站<a
                href="/about/agree"
                target="_blank"
                >《用户协议》</a
              >，严禁搜索非法关键词。
            </p>
            <p>
              4.
              {{
                PROJECT_NAME
              }}高度重视知识产权保护和个人隐私保护，如有网盘链接侵犯您的合法权益，请立即向百度网盘官方网站举报，并参见本站<a
                href="/about/version"
                target="_blank"
                >《版权说明》</a
              >提供书面材料联系我们屏蔽删改。
            </p>
            <p>
              5. {{ PROJECT_NAME }}作为非经营性网站，所有服务仅供学习交流使用。
            </p>
          </div>

          <div class="compatible-wrap" v-if="!detail">
            <img src="@/assets/images/error.png" alt="当前访问人数过多" />
            <p>该链接地址已不可用，或者无法搜索到该文件ID对应的资源</p>
          </div>

          <div class="compatible-wrap" v-if="detail == 'limited'">
            <img src="@/assets/images/error.png" alt="当前访问人数过多" />
            <p class="none-tip">当前访问人数过多，请稍后重试!</p>
          </div>
          <div class="compatible-wrap" v-if="isSpecified(detail)">
            <img src="@/assets/images/error.png" />
            <p class="none-tip">{{ detail }}</p>
            <p>如有误判，可以联系客服，QQ：3556044645</p>
          </div>
        </template>
        <div class="loading-wrap" v-if="loading">
          <lottie-player
            class="animation"
            src="https://res.yimiaopan.com/pan/lottie/search.json"
            background="transparent"
            speed="0.4"
            loop
            autoplay
          />
          <p>资源搜索中...</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUrl } from "../api/user";
import { checkUrlFromBaidu, getDetail } from "../api/search";
import copyToClipboard from "../utils/clipboard";

const formatUrl = (url) => {
  if (!url) {
    return "";
  }
  const index = url.lastIndexOf("http");
  if (index > 0) {
    return url.slice(index);
  } else {
    return url;
  }
};

export default {
  validate({ params }) {
    return params.id;
  },
  data: () => ({
    id: "",
    pwd: "",
    url: "",
    type: "",
    parent: "",
    valid: 0,
    detail: null,
    mask: false,
    isCopied: false,
    loading: true,
    isCheckingUrlValid: true,
    qrcodeTitle: "",
    qrcodeContent: "",
  }),
  async mounted() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.parent = this.$route.query.parent || "";
    let TIMES = 0;
    if (this.id) {
      await this.getResourceDetail();
      if (this.valid == 0) this.checkUrlValid(this.id);
      if (!this.url) {
        await this.getResourceUrl();
        const timer = clearInterval(async () => {
          if (TIMES < 5 && !this.url) {
            await this.getResourceUrl();
          } else {
            clearInterval(timer);
          }
        }, 300);
      }
    }
  },
  methods: {
    isSpecified(data) {
      return (
        data &&
        typeof data === "string" &&
        ![
          "toomuch",
          "limited",
          "unionid_limited",
          "blocked",
          "politics",
          "porn",
          "contraband",
          "terrorism",
          "privacy",
        ].includes(data)
      );
    },
    copy(content) {
      this.isCopied = copyToClipboard(content);
    },

    async checkUrlValid(id) {
      this.isCheckingUrlValid = true;
      const res = await checkUrlFromBaidu(id);
      if (res) {
        this.valid = res[id];
      }
      this.isCheckingUrlValid = false;
    },

    async getResourceDetail() {
      this.loading = true;
      if (this.id) {
        this.detail = await getDetail({
          id: this.id,
          type: this.type,
          parent: this.parent,
          size: 15,
        });
        if (this.detail && this.detail.filename) {
          this.pwd = this.detail.pwd;
        }
        if (this.detail && this.detail.url) {
          this.url = formatUrl(this.detail.url);
          this.valid = 1;
          this.isCheckingUrlValid = false;
          if (
            this.type === "aliyundrive" &&
            this.parent &&
            this.parent != "undefined"
          ) {
            this.url = `${this.url}/folder/${this.parent}`;
          }

          if (this.pwd) {
            if (this.url.lastIndexOf("?") > -1) {
              this.url = `${this.url}&pwd=${this.pwd}`;
            } else {
              this.url = `${this.url}?pwd=${this.pwd}`;
            }
          }
        }
      }
      this.loading = false;
    },

    async getResourceUrl() {
      const res = await getUrl(this.id);
      if (res.code == 0) {
        this.url = res.data;
        if (
          this.type === "aliyundrive" &&
          this.parent &&
          this.parent != "undefined"
        ) {
          this.url = `${this.url}/folder/${this.parent}`;
        }
        if (this.pwd) {
          if (this.url.lastIndexOf("?") > -1) {
            this.url = `${this.url}&pwd=${this.pwd}`;
          } else {
            this.url = `${this.url}?pwd=${this.pwd}`;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.detail-page {
  .detail-page-inner {
    padding-bottom: 20px;
  }

  #info,
  #statement {
    padding: 25px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-top: 15px;
    position: relative;

    h2 {
      font-size: 16px;
      padding-left: 10px;
      line-height: 1.3;
      margin-bottom: 15px;
      border-left: 4px solid $theme-color;
    }

    .content-inner {
      h3 {
        padding-left: 15px;
        position: relative;
        word-break: break-all;

        &:before {
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: rgba($theme-color, 0.3);
        }
      }

      a {
        line-height: 1.6;
        font-size: 14px;
        font-weight: normal;
        color: #555;
        transition: ease 0.3s color;
        text-decoration: none;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  #info {
    min-height: 200px;
    margin-top: 0;

    .filename {
      text-align: center;
      margin-bottom: 20px;
    }

    .complaint {
      position: absolute;
      right: 15px;
      bottom: 2px;
      font-size: 14px;
    }

    .detail-inner-wrap {
      padding: 10px 20px;
      text-align: left;
      margin: 15px auto;
      border-radius: 2px;
      background-color: #f8f8f8;

      .detail-item-wrap {
        span {
          font-size: 13px;
          display: inline;
          line-height: 1.8;
          color: #666;
        }

        .detail-item-title {
          font-weight: normal;
          position: relative;
          padding-left: 15px;
          display: inline;
          word-break: break-all;

          &:before {
            content: "|-";
            position: absolute;
            top: -6px;
            left: 0;
          }
        }
      }
    }

    .resource-meta {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 13px;
      color: #333;
      text-align: center;

      span {
        display: inline-block;
      }

      .meta-item {
        margin-right: 10px;
        border-radius: 2px;
        padding-right: 6px;
        display: inline-block;
        border: 1px solid #ddd;
        background-color: #f8f8f8;

        &:last-child {
          margin-right: 0;
        }

        &.copy-item,
        &.report {
          padding-right: 0;

          .label {
            margin-right: 0;
          }
        }

        .label {
          padding: 4px 6px;
          margin-right: 4px;
          background: #f2f2f2;
          border-right: 1px solid #ddd;
        }

        .copy {
          cursor: pointer;
          color: $theme-color;
          padding: 4px 6px;
          background-color: rgba($theme-color, 0.2);
          border-left: 1px solid #ddd;
          transition: all ease 0.2s;
          font-weight: bold;
        }
      }

      .tip {
        background-color: #eee;
        color: $theme-color;
        padding: 4px 10px;
        border-radius: 2px;
      }
    }

    .button-wrap {
      text-align: center;
      margin-top: 10px;

      .button {
        display: inline-block;
        color: #fff;
        height: 35px;
        line-height: 35px;
        width: 150px;
        font-size: 12px;
        border-radius: 2px;
        background-color: $theme-color;
        transition: background-color ease 0.3s;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        margin-right: 15px;
        position: relative;

        &.copy {
          color: $theme-color;
          background-color: rgba($theme-color, 0.2);

          &:hover {
            background-color: rgba($theme-color, 0.4);
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: darken($theme-color, 10%);
        }

        &.invalid {
          color: #ddd;
          text-decoration: line-through;
        }
      }
    }

    .check-wrap {
      font-size: 15px;
      text-align: center;
      font-weight: bold;

      .checking-tip {
        color: #666;
      }

      .result-tip {
        .valid {
          color: #41b035;
        }

        .invalid {
          color: red;
        }
      }
    }
  }

  #statement {
    margin-top: 20px;

    p {
      margin-top: 5px;
      color: rgb(102, 102, 102);
      font-weight: bold;
      font-size: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .em {
      font-weight: bold;
      color: rgba($theme-color, 0.7);
      text-align: justify;
    }
  }

  .compatible-wrap {
    padding: 30px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    text-align: center;

    img {
      width: 200px;
    }

    p {
      font-size: 16px;
      margin-top: 20px;
    }

    .hot-wrap {
      margin-top: 30px;

      .none-tip {
        font-weight: bold;
        margin-bottom: 15px;
      }
    }
  }
}

.loading-wrap {
  text-align: center;

  .animation {
    height: 160px;
  }
}

@media (max-width: 700px) {
  .detail-page {
    .detail-page-inner {
      margin-bottom: 0;
      display: block;
    }

    h1 {
      font-size: 18px;
    }

    #info {
      padding: 20px;

      .filename {
        padding-top: 10px;
        word-break: break-all;
      }

      .detail-inner-wrap {
        .detail-item-wrap {
          span {
            font-size: 11px;
          }
        }
      }

      .resource-meta {
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .meta-item {
          margin-bottom: 10px;
          margin-right: 0;
          font-size: 11px;
        }
      }

      .button-wrap {
        .button-inner {
          margin-right: 0;
        }

        .button {
          width: 150px;
          margin-bottom: 15px;
        }
      }
    }

    .compatible-wrap {
      margin-top: 15px;
    }

    #statement {
      p {
        font-size: 13px;
      }
    }
  }
}
</style>
